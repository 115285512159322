<template>
  <div>
    <el-container>
      <el-main>
        <img src="/img/homepb.png" />
      </el-main>
      <el-aside>
        <h3>
          功能更新
          <i class="el-icon-bell"></i>
        </h3>
        <div style="margin-bottom: 10px" v-for="item in array" :key="item">
          <el-alert
            :title="item"
            type="success"
            show-icon
            :closable="false"
          ></el-alert>
        </div>
      </el-aside>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      array: [
        "仓库收发存报表增加物资类别过滤",
        "项目物资消耗表增加物资类别过滤",
        "业务查找付款单添加操作员过滤",
      ],
    };
  },
};
</script>
<style scoped>
.el-main {
  padding: 0;
}
</style>
